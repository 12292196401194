import React, { useState } from 'react';
import A1 from '../Images/Elogo.png';
import './sidebar.css';
import { FaTachometerAlt, FaServicestack, FaFileInvoice, FaMoneyCheckAlt, FaBox } from 'react-icons/fa';

function Sidebar({ openSidebarToggle, OpenSidebar }) {
    const [isCustomerDropdownOpen, setCustomerDropdownOpen] = useState(false);

    const toggleCustomerDropdown = () => {
        setCustomerDropdownOpen(prevState => !prevState);
    };

    return (
        <aside id="sidebar" className={openSidebarToggle ? "sidebar-responsive" : ""}>
            <div className='sidebar-title'>
                <div className='sidebar-brand'>
                    <img src={A1} alt='nir' style={{ height: '40px' }} />
                </div>

                <span className='icon close_icon' onClick={OpenSidebar}>X</span>
            </div>

            <ul className='sidebar-list'>
                <li className='sidebar-list-item'>
                    <a href="/dashboard">
                        <FaTachometerAlt className='icon' /> Dashboard
                    </a>
                </li>
                <li className='sidebar-list-item'>
                    <a href="/adpackages/adpackages">
                        <FaBox className='icon' /> Packages
                    </a>                 
                </li>
                <li className='sidebar-list-item'>
                    <a href="/invoices/invoices">
                        <FaFileInvoice className='icon' /> Invoices
                    </a>            
                </li>
                <li className='sidebar-list-item'>
                    <a href="/payments/Payments">
                        <FaMoneyCheckAlt className='icon' /> Payments
                    </a>            
                </li>
            </ul>
        </aside>
    );
}

export default Sidebar;
