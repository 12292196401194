import React, { useState } from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import bg from '../Images/bg1.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EmailVerification = () => {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [verified, setVerified] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ssapi.emedha.in/auth/api/verify-otp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, otp }),
            });

            const data = await response.json();

            if (response.ok) {
                setVerified(true);
                setMessage(data.message);
                setError('');
                alert('Email verified successfully!');
                navigate('/login');
            } else {
                setMessage('');
                setError(data.error || 'Verification failed. Please try again.');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            setError('An error occurred while verifying OTP.');
        }
    };

    return (
        <>
            <Landingheader />
            <div className='mt-2'>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
                    <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
                    <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
                        <form onSubmit={handleSubmit}>
                            <h1>Email Verification</h1>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <div style={{ margin: '15px 0' }}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>
                            <div style={{ margin: '15px 0' }}>
                                <input
                                    type="text"
                                    placeholder="Enter OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>
                            <ReCAPTCHA className='mb-2' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />
                            <button style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                                Verify
                            </button>
                        </form>
                        {verified && <Alert variant="success" style={{ marginTop: '20px' }}>Email verified!</Alert>}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default EmailVerification;
