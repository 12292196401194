import React from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import a1 from '../Images/a1.png';
import a2 from '../Images/a2.png';
import a3 from '../Images/a3.png';
import a4 from '../Images/a4.png';

const Packages = () => {
    const services = [
        {
            img: a1,
            title: 'Senior Social Centre',
            description: 'Discover unmatched community support',
            price: '₹9999*',
            duration: 'For 3 months (90hr)',
            additional: 'Taxes applicable',
            features: [
                'Leisure Care',
                'Access to Online/Offline Events*',
                'Flexible Timings',
                'Entertainment Activities',
            ],
        },
        {
            img: a2,
            title: 'Day Care',
            description: 'Care and comfort you can trust',
            price: '₹21999*',
            duration: 'Mon-Fri (10 AM to 5 PM)',
            additional: 'Taxes applicable',
            features: [
                'Social Engagement',
                'Physical and Mental Well-Being',
                'Healthy Nutritious Meals',
                'Medical Supervision',
            ],
        },
        {
            img: a3,
            title: 'Assisted Living',
            description: 'Promoting independence with a helping hand',
            priceSingle: '₹50000* (Single Occupancy)',
            priceDouble: '₹35000* (Double Occupancy)',
            additional: 'Taxes applicable',
            features: [
                '24/7 Basic Care and Support',
                'Healthy Nutritious Meals',
                'Medicine Guidance',
                'Regular Health Checkups',
            ],
        },
        {
            img: a4,
            title: 'Transition Care',
            description: 'Non-clinical, out of hospital care support',
            price: '₹3000* Per Day',
            additional: 'Prices vary depending on the care required. Taxes applicable',
            features: [
                '24/7 Nursing care',
                'Physiotherapy',
                'Occupational Therapy',
                'Healthy Nutritious Meals',
            ],
        },
    ];

    return (
        <>
            <Landingheader />
            <div className="container" style={{ marginTop: '80px', backgroundColor: '#f3ebe4' }}>
                <h1 className="text-center mb-4"><strong>Care Packages</strong></h1>
                
                <div className="row">
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-6 col-md-6 col-sm-12 mb-4">
                            <div className="d-flex flex-column flex-md-row">
                                <img
                                    src={service.img}
                                    alt={service.title}
                                    className="img-fluid"
                                    style={{ width: '100%', maxHeight: '200px', objectFit: 'cover', borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}
                                />
                                <div className="p-3" style={{ width: '100%' }}>
                                    <h5 style={{ color: '#333', fontFamily: 'Arial, Helvetica, sans-serif' }}>{service.title}</h5>
                                    <p style={{ color: '#808080', lineHeight: '1.5', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif' }}>{service.description}</p>
                                    <h6 style={{ color: '#07526f', fontSize: 'xx-large', fontWeight: 'bold', fontFamily: '"Segoe UI", Tahoma, Geneva, Verdana, sans-serif' }}>
                                        {service.price || service.priceSingle || service.priceDouble}
                                    </h6>
                                    {service.duration && <p style={{ color: '#666' }}>{service.duration}</p>}
                                    <p style={{ color: '#666' }}>{service.additional}</p>
                                    <ul className="list-unstyled">
                                        {service.features.map((feature, idx) => (
                                            <li key={idx} style={{ marginBottom: '5px' }}>- {feature}</li>
                                        ))}
                                    </ul>
                                    <button
                                        className="btn "
                                        style={{ width: '100%' ,backgroundColor:'#07526f',color:'white'}}
                                    >
                                        Request Callback
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Packages;
   