import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Landingheader from './Components/Header/Landingheader';
import Footer from './Components/Footer/footer';
import Home from './Components/Pages/home';
import Ssc from './Components/Pages/ssc';
import About from './Components/Pages/about';
import Packages from './Components/Pages/packages';
import Daycare from './Components/Pages/daycare';
import Gallery from './Components/Pages/gallery';
import Assistedliving from './Components/Pages/assistedliving';
import Transitioncare from './Components/Pages/transitioncare';
import Login from './Components/Auth/login';
import Signup from './Components/Auth/Signup';
import Vendorsignup from './Components/Auth/vendorsignup';
import EmailVerification from './Components/Auth/emailverification';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetPassword from './Components/Auth/ResetPassword';
import Dashboard from './Components/Sidebar/dashboard';
import Payments from './Components/Sidebar/Payments';
import Invoices from './Components/Sidebar/Invoices';
import Notifications  from './Components/Sidebar/notifications';
import Interimcare from './Components/Pages/Interimcare';
import Adpackages from './Components/Sidebar/Adpackages';


function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path='/landingpage' element={<Landingheader />} />
        <Route path='/footer' element={<Footer />} />
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<About />} />
        <Route path='/packages/packages' element={<Packages />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/vendor' element={<Vendorsignup />} />
        <Route path='/otp-verification' element={<EmailVerification />} />
        <Route path='/reset-password/:token' element={<ResetPassword />} />
        <Route path='/forgotpassword' element={<ForgotPassword />} />
        <Route path='/services/senior-social-center' element={<Ssc />} />
        <Route path='/services/day-care' element={<Daycare />} />
        <Route path='/gallery/gallery' element={<Gallery />} />
        <Route path='/services/assisted-living' element={<Assistedliving />} />
        <Route path='/services/transition-care' element={<Transitioncare/>}/>
        <Route path='/services/interim-care' element={<Interimcare/>}/>
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path='/invoices/invoices' element={<Invoices />} />
        <Route path='/payments/Payments' element={<Payments />} />
        <Route path='/notifications/notifications' element={<Notifications />} />
        <Route path='/Adpackages/Adpackages' element={<Adpackages />} />
      </Routes>
    </Router>
  </>
  );
}

export default App;
