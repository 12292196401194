import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Alert, } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import bg from '../Images/bg1.png';



const ResetPassword = () => {
  const { token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await fetch(`https://ssapi.emedha.in/auth/api/reset-password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess('Password reset successfully.');
        setError('');
        setNewPassword('');
        setConfirmPassword('');
        setTimeout(() => navigate('/login'), 2000); // Redirect after 2 seconds
      } else {
        setError(data.error || 'Failed to reset password');
        setSuccess('');
      }
    } catch (error) {
      console.error('Reset password error:', error);
      setError('Failed to reset password');
      setSuccess('');
    }
  };

  return (
    <>
      <Landingheader />
      <div className='mt-2'>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
          <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
          <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
            <h1>Forgot Password </h1>

            <Form onSubmit={handleResetPassword}>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <div style={{ position: 'relative', width: '100%', margin: '30px 0', }}>
                <input type="newPassword" placeholder="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} required />
                <i className='bx bx-envelope' style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px', }}></i>
              </div>

              <div style={{ position: 'relative', width: '100%', margin: '30px 0', }}>
                <input type="confirmPassword" placeholder="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} required />
                <i className='bx bx-envelope' style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px', }}></i>
              </div>

              <ReCAPTCHA className='mb-2'
                sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"
              />
              <button style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                Save New Password
              </button>

            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
