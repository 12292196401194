import React, { useState } from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import a1 from '../Images/a1.png';
import a2 from '../Images/a2.png';
import a3 from '../Images/a3.png';
import a4 from '../Images/a4.png';
import d1 from '../Images/d1.png';
import d2 from '../Images/d2.png';
import d3 from '../Images/d3.png';
import d4 from '../Images/d4.png';
import d5 from '../Images/d5.png';
import d6 from '../Images/d6.png';

const Gallery = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    
    const images = [a1, a2, a3, a4, d1, d2, d3, d4, d5, d6];

    const hoverStyle = {
        transform: 'scale(1.05)',
        transition: 'transform 0.3s',
    };

    return (
        <>
            <Landingheader />
            <div style={{ marginTop: '80px', padding: '20px' }}>
                <h2 className="text-center mb-4">Gallery</h2>
                <div className="container">
                    <div className="row">
                        {images.map((img, index) => (
                            <div
                                key={index}
                                className="col-6 col-md-4 col-lg-3 mb-3"
                                onMouseEnter={() => setHoveredIndex(index)}
                                onMouseLeave={() => setHoveredIndex(null)}
                            >
                                <div
                                    style={{
                                        overflow: 'hidden',
                                        borderRadius: '8px',
                                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                        transition: 'transform 0.3s',
                                        ...(hoveredIndex === index ? hoverStyle : {}),
                                    }}
                                >
                                    <img
                                        src={img}
                                        alt={`Gallery ${index}`}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block',
                                            borderRadius: '8px',
                                        }}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Gallery;
