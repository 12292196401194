import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Sidebar from '../Sidebar/sidebar';
import Header from './Header';
import { FaUsers, FaBox, FaStore } from 'react-icons/fa';

const Dashboard = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [packages, setPackages] = useState(0); // Initialize packages count
    const [vendors, setVendors] = useState(1); // Placeholder for vendors count
    const [users, setUsers] = useState(2); // Placeholder for users count

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await axios.get('https://ssapi.emedha.in/packages/api/packages');
                setPackages(response.data.length); // Assuming response.data is an array
            } catch (error) {
                console.error('Error fetching packages:', error);
            }
        };
        fetchPackages();
    }, []);

    const hoverStyle = {
        transform: 'scale(1.05)',
    };

    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

                <div className='main-container'>
                    <h4 style={{ color: '#08b4f3' }}><strong>ADMIN DASHBOARD</strong></h4>
                    <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '20px', marginTop: '20px' }}>
                        <div style={{ backgroundColor: '#07526f', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', textAlign: 'center', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', transition: 'transform 0.2s', cursor: 'pointer' }}
                            onMouseOver={(e) => e.currentTarget.style.transform = hoverStyle.transform}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaStore style={{ fontSize: '24px', color: 'white', marginRight: '8px' }} />
                                No. of Vendors
                            </h5>
                            <p style={{ color: 'white' }}>{vendors}</p>
                        </div>

                        <div style={{ backgroundColor: '#789ca4', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', textAlign: 'center', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', transition: 'transform 0.2s', cursor: 'pointer' }}
                            onMouseOver={(e) => e.currentTarget.style.transform = hoverStyle.transform}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaUsers style={{ fontSize: '24px', color: 'white', marginRight: '8px' }} />
                                No. of Users
                            </h5>
                            <p style={{ color: 'white' }}>{users}</p>
                        </div>

                        <div style={{ backgroundColor: '#36708e', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', textAlign: 'center', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', transition: 'transform 0.2s', cursor: 'pointer' }}
                            onMouseOver={(e) => e.currentTarget.style.transform = hoverStyle.transform}
                            onMouseOut={(e) => e.currentTarget.style.transform = 'scale(1)'}>
                            <h5 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <FaBox style={{ fontSize: '24px', color: 'white', marginRight: '8px' }} />
                                No. of Packages
                            </h5>
                            <p style={{ color: 'white' }}>{packages}</p>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default Dashboard;
