import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {  Form, Alert } from 'react-bootstrap';
import ReCAPTCHA from "react-google-recaptcha";
import bg from '../Images/bg1.png';
import Footer from '../Footer/footer';
import Landingheader from '../Header/Landingheader';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(''); // Clear previous errors

    try {
      const response = await fetch('https://ssapi.emedha.in/auth/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setResetToken(data.resetToken); // Assuming your backend returns resetToken upon successful request
        setResetPasswordMode(true);
      } else {
        setError(data.error || 'Failed to send password reset email');
      }
    } catch (error) {
      setError('Failed to send password reset email');
    }
  };

  return (
    <>
      <Landingheader />
      <div className='mt-2'>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
          <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
          <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
            <h1>Forgot Password </h1>
            <Form onSubmit={handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              {resetPasswordMode && <Alert variant="success">Check your email for a password reset link.</Alert>}

              <div style={{ position: 'relative', width: '100%', margin: '30px 0', }}>
                <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} required />
                <i className='bx bx-envelope' style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px', }}></i>
              </div>

              <ReCAPTCHA className='mb-3'
                sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU"
              />
              <button style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                Reset Password
              </button>

            </Form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ForgotPassword;
