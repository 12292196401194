import React, { useState } from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import bg from '../Images/bg1.png';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Signup = () => {
    const [vendorname, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [vendorType, setVendorType] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const Navigate = useNavigate()

    const handleSignup = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }

        try {
            const response = await fetch('https://ssapi.emedha.in/auth/api/vendor-signup', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ vendorname, email, mobile, vendortype: vendorType, password }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setError('');
                Navigate('/otp-verification')
            } else {
                setMessage('');
                setError(data.error || 'Signup failed. Please try again.');
            }
        } catch (error) {
            console.error('Error signing up:', error);
            setError('An error occurred while signing up.');
        }
    };

    return (
        <>
            <Landingheader />
            <div style={{marginTop:'80px'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
                    <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
                    <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
                        <form onSubmit={handleSignup}>
                            <h3>Vendor Registration</h3>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input
                                    type="text"
                                    placeholder="Vendor Name"
                                    value={vendorname}
                                    onChange={(e) => setName(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input
                                    type="tel"
                                    placeholder="Mobile"
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <select
                                    value={vendorType}
                                    onChange={(e) => setVendorType(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: '2px solid #fff', borderRadius: '5px', color: '#fff', fontSize: '16px', padding: '10px', }}
                                    required
                                >
                                    <option value="" disabled>Select Vendor Type</option>
                                    <option value="Senior Social Center" className='text-black'>Senior Social Center</option>
                                    <option value="Day Care" className='text-black'>Day Care</option>
                                    <option value="Assisted Living" className='text-black'>Assisted Living</option>
                                    <option value="Transition Care" className='text-black'>Transition Care</option>
                                    <option value="Interim Care" className='text-black'>Interim Care</option>
                                </select>
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                            </div>

                            <button style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                                Sign Up
                            </button>

                            <div style={{ fontSize: '14px', marginTop: '20px', textAlign: 'center' }}>
                                <p>Already have an account? <br /> <a href="#" style={{ color: 'white' }}>Login</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Signup;
