import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa';


const Footer = () => {



    return (
        <footer className="text-dark py-4 mt-2" style={{ backgroundColor: '#b7c7d2' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mb-3">
                        <h5>Services</h5>
                        <ul className="list-unstyled">
                            <li className='mb-1'><a href="#" className="text-dark footer-link">Senior Social Center</a></li>
                            <li className='mb-1'><a href="#" className="text-dark footer-link">Day Care</a></li>
                            <li className='mb-1'><a href="#" className="text-dark footer-link">Assisted Living</a></li>
                            <li className='mb-1'><a href="#" className="text-dark footer-link">Transition Care</a></li>
                            <li className='mb-1'><a href="#" className="text-dark footer-link">Interim Care</a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h5>Contact Us</h5>
                        <p>Secunderabad, Telangana-500015</p>
                        <p>Call: <a href="tel:0000000000" className="text-dark footer-link">0000000000</a></p>
                        <p>Email: <a href="mailto:info@emedha.com" className="text-dark footer-link">info@emedha.com</a></p>
                    </div>
                    <div className="col-md-4 mb-3">
                        <h5>Stay Connected</h5>
                        <div className="social-media mb-3">
                            <a href="#" className="text-dark footer-link me-5">
                                <FaFacebook /> 
                            </a>
                            <a href="#" className="text-dark footer-link me-5">
                                <FaTwitter /> 
                            </a>
                            <a href="#" className="text-dark footer-link">
                                <FaInstagram /> 
                            </a>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-4">
                    <p>&copy; 2024 E-medha. All rights reserved.</p>
                    <p>
                        <a href="#" className="text-dark footer-link me-3">Terms and Conditions</a>
                        <a href="#" className="text-dark footer-link">Privacy Policy</a>
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
