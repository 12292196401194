import React from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import ic1 from '../Images/a1.png';
import ic2 from '../Images/ic2.png';

const Interimcare = () => {
    return (
        <>
            <Landingheader />
            <div className="container mt-5 p-4 rounded">
                <h1 className="text-center">Interim Care</h1>
                <p className="text-center mb-4">
                    Interim care provides temporary support to individuals needing assistance during recovery or transitional periods. Our dedicated team ensures comfort and quality care tailored to each individual’s needs.
                </p>

                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="shadow-sm">
                            <img
                                src={ic1}
                                alt="Interim Care Image 1"
                                className="img-fluid"
                                style={{ height: '300px', objectFit: 'cover' }}
                            />
                        </div>
                        <h3 className="text-center mt-2">Personalized Care</h3>
                        <p className="text-center">
                            We offer personalized care plans to meet each patient's unique needs, ensuring a smooth recovery. Our caregivers assess individual circumstances and develop a strategy that promotes healing and well-being.
                        </p>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="shadow-sm">
                            <img
                                src={ic2}
                                alt="Interim Care Image 2"
                                className="img-fluid"
                                style={{ height: '300px', objectFit: 'cover' }}
                            />
                        </div>
                        <h3 className="text-center mt-2">Compassionate Support</h3>
                        <p className="text-center">
                            Our compassionate staff is here to support you every step of the way, providing emotional and physical assistance. We understand that recovery can be challenging, and our goal is to provide a nurturing environment.
                        </p>
                    </div>
                </div>

                <div className="text-center mt-4">
                    <h3>Why Choose Interim Care?</h3>
                    <p>
                        Choosing interim care means choosing a partner in your recovery journey. Our services include:
                    </p>
                    <div className="row">
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow-lg rounded flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title">24/7 Availability of Trained Caregivers</h5>
                                    <p className="card-text">
                                        Our caregivers are available around the clock, ensuring that support is always within reach. Whether you need assistance during the day or overnight, we're here for you.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow-lg rounded flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title">Customized Care Plans Tailored to Individual Needs</h5>
                                    <p className="card-text">
                                        Each individual is unique, and we take the time to understand your specific requirements. Our care plans are personalized to provide the most effective support for your recovery.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow-lg rounded flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title">Support for Daily Activities and Personal Care</h5>
                                    <p className="card-text">
                                        We assist with daily tasks, such as bathing, dressing, and meal preparation, to ensure your comfort and dignity during recovery.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow-lg rounded flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title">Medication Management and Health Monitoring</h5>
                                    <p className="card-text">
                                        Our trained staff helps manage medications and monitors health conditions, ensuring that all medical needs are met efficiently.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4 d-flex">
                            <div className="card shadow-lg rounded flex-fill">
                                <div className="card-body">
                                    <h5 className="card-title">Emotional and Mental Health Support</h5>
                                    <p className="card-text">
                                        We provide emotional support and mental health resources to help you cope with the challenges of recovery. Our caregivers are trained to listen and assist with emotional well-being.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    );
};

export default Interimcare;
