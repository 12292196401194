import React from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import tc1 from '../Images/tc1.png';
import tc2 from '../Images/tc2.png';

const TransitionCare = () => {
    return (
        <>
            <Landingheader />
            <div className="container" style={{marginTop:'80px'}}>
                <h1 className="text-center mb-4">Transition Care Services</h1>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <img src={tc1} alt="Personalized Care" className="img-fluid rounded" />
                    </div>
                    <div className="col-md-6">
                        <h2>Personalized Care Plans</h2>
                        <p>
                            Our transition care program offers tailored care plans designed to meet the individual needs of each resident. Our dedicated staff works closely with families and healthcare providers to ensure seamless transitions and continuity of care.
                        </p>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <h2>Comprehensive Support</h2>
                        <p>
                            We provide comprehensive support during the transition process, including emotional, physical, and social assistance. Our team is trained to handle the unique challenges that come with transitioning to a new living environment.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <img src={tc2} alt="Supportive Environment" className="img-fluid rounded" />
                    </div>
                </div>

                <div className="text-center">
                    <h3>Why Choose Our Transition Care?</h3>
                    <div className="row">
                        {[
                            { 
                                icon: '🛌', 
                                heading: 'Comfortable and Safe Living Spaces', 
                                content: 'Our facilities provide a warm and welcoming atmosphere where residents can feel secure and at home. Each room is designed for comfort and accessibility, ensuring peace of mind for both residents and families.' 
                            },
                            { 
                                icon: '👩‍⚕️', 
                                heading: 'Skilled Nursing and Personal Care', 
                                content: 'Our trained nursing staff offers 24/7 assistance, providing medications, personal hygiene support, and emergency care when needed. We prioritize the health and well-being of every resident.' 
                            },
                            { 
                                icon: '🍽️', 
                                heading: 'Nutritious Meals and Dietary Management', 
                                content: 'We prepare delicious and nutritious meals tailored to individual dietary needs. Our chefs work with dietitians to ensure balanced meals that promote health and wellness.' 
                            },
                            { 
                                icon: '📅', 
                                heading: 'Regular Health Check-Ups and Monitoring', 
                                content: 'We conduct routine health assessments to monitor residents’ health status, ensuring any changes are promptly addressed. Our proactive approach keeps everyone in optimal health.' 
                            },
                            { 
                                icon: '🎉', 
                                heading: 'Engaging Activities for Social Interaction', 
                                content: 'We offer a variety of activities and events designed to foster social connections among residents. From arts and crafts to group outings, we encourage participation and community engagement.' 
                            },
                        ].map((item, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <div style={{ 
                                    border: '1px solid #007bff', 
                                    borderRadius: '10px', 
                                    padding: '20px', 
                                    backgroundColor: '#f8f9fa' 
                                }}>
                                    <h4>{item.icon} {item.heading}</h4>
                                    <p>{item.content}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default TransitionCare;
