import React from 'react';
import a1 from '../Images/a1.png';
import a2 from '../Images/a2.png';
import a3 from '../Images/a3.png';
import a4 from '../Images/a4.png';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const ssc = () => {
    return (
        <>
            <Landingheader />
            <div className="container" style={{marginTop:'80px'}}>
                <h1 className="text-center">Senior Social Center</h1>
                <p className="text-center">
                    Welcome to the Senior Social Center! Our mission is to provide a supportive and engaging environment for seniors in our community. We offer a variety of programs and activities designed to promote social interaction, wellness, and lifelong learning.
                </p>

                <div className="row mb-4">
                    <div className="col-md-3">
                        <img src={a1} alt="Activity 1" className="img-fluid rounded shadow-sm mb-3" />
                    </div>
                    <div className="col-md-3">
                        <img src={a2} alt="Activity 2" className="img-fluid rounded shadow-sm mb-3" />
                    </div>
                    <div className="col-md-3">
                        <img src={a3} alt="Activity 3" className="img-fluid rounded shadow-sm mb-3" />
                    </div>
                    <div className="col-md-3">
                        <img src={a4} alt="Activity 4" className="img-fluid rounded shadow-sm mb-3" />
                    </div>
                </div>

                <h2 className="text-center">Programs Offered:</h2>
                <div className="row">
                    <div className="col-md-4 mb-4">
                        <div className="p-3 border rounded shadow-sm">
                            <h5>Day Care</h5>
                            <p>Providing a safe and stimulating environment for seniors to engage in daily activities.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="p-3 border rounded shadow-sm">
                            <h5>Assisted Living</h5>
                            <p>Support with daily activities in a community setting, encouraging independence.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="p-3 border rounded shadow-sm">
                            <h5>Transition Care</h5>
                            <p>A supportive bridge for seniors transitioning from hospital to home or assisted living.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="p-3 border rounded shadow-sm">
                            <h5>Interim Care</h5>
                            <p>Short-term care services to assist seniors after surgery or during recovery.</p>
                        </div>
                    </div>
                    <div className="col-md-4 mb-4">
                        <div className="p-3 border rounded shadow-sm">
                            <h5>Senior Social Center</h5>
                            <p>Engaging social activities and programs to foster connections and community.</p>
                        </div>
                    </div>
                </div>
                
                <p className="text-center mt-4">
                    Join us to meet new friends, learn new skills, and enjoy a vibrant community!
                </p>
            </div>
            <Footer />
        </>
    );
};

export default ssc;
