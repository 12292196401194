import React, { useState } from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import bg from '../Images/bg1.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ssapi.emedha.in/auth/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (response.ok) {
                setMessage(data.message);
                setError('');
                localStorage.setItem('token', data.token);
                navigate('/dashboard'); // Redirect to dashboard or home
            } else {
                setMessage('');
                setError(data.error || 'Login failed. Please try again.');
            }
        } catch (error) {
            console.error('Error logging in:', error);
            setError('An error occurred while logging in.');
        }
    };

    return (
        <>
            <Landingheader />
            <div style={{marginTop:'80px'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
                    <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
                    <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
                        <form onSubmit={handleLogin}>
                            <h1>Login</h1>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && <Alert variant="success">{message}</Alert>}
                            <div style={{ position: 'relative', width: '100%', margin: '30px 0', }}>
                                <input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                                <i className='bx bx-envelope' style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px', }}></i>
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '30px 0', }}>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }}
                                    required
                                />
                                <i className='bx bx-lock-alt' style={{ position: 'absolute', right: '20px', top: '50%', transform: 'translateY(-50%)', fontSize: '20px', }}></i>
                            </div>

                            <ReCAPTCHA className='mb-2' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

                            <div className='mt-4' style={{ display: 'flex', justifyContent: 'space-between', fontSize: '15px', margin: '-10px 0 15px', }}>
                                <label>
                                    <input type="checkbox" /> Remember me
                                </label>
                                <a href="/forgotpassword" style={{ color: 'white' }}>Forgot password?</a>
                            </div>

                            <button style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                                Login
                            </button>

                            <div style={{ fontSize: '14px', marginTop: '20px', textAlign: 'center', }}>
                                <p>Don't have an account? <br /> <a href="/signup" style={{ color: 'white' }}>Register</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Login;
