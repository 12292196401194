import React, {  useState } from 'react';
import Sidebar from '../Sidebar/sidebar';
import Header from './Header';

const Payments = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);


    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

   

    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

              <div className='main-container'>
                <h4 style={{color:'#08b4f3'}}>PAYMENTS</h4>
              </div>
            </div>
        </>
    );
}

export default Payments;
