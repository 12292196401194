import React from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';

import d1 from '../Images/d1.png';
import d2 from '../Images/d2.png';
import d3 from '../Images/d3.png';
import d4 from '../Images/d4.png';
import d5 from '../Images/d5.png';
import d6 from '../Images/d6.png';

const AssistedLiving = () => {
    return (
        <>
            <Landingheader />
            <div className="container " style={{marginTop:'80px'}}>
                <h1 className="text-center mb-4">Experience Compassionate Care and Vibrant Living</h1>
                
                <div className="row mb-4">
                    <div className="col-md-4">
                        <img src={d1} alt="Home-Cooked Meals" className="img-fluid rounded" />
                        <h5 className="mt-2">Home-Cooked Meals</h5>
                        <p>Enjoy nutritious and delicious meals prepared by our dedicated chefs, tailored to meet dietary needs.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={d2} alt="Assistance and Support" className="img-fluid rounded" />
                        <h5 className="mt-2">Assistance and Support</h5>
                        <p>Our caring staff provides personalized support to ensure comfort and safety at all times.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={d3} alt="Expert Medicine Guidance" className="img-fluid rounded" />
                        <h5 className="mt-2">Expert Medicine Guidance</h5>
                        <p>Receive assistance with medication management from trained professionals who prioritize your health.</p>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-4">
                        <img src={d4} alt="Routine Health Checkups" className="img-fluid rounded" />
                        <h5 className="mt-2">Routine Health Checkups</h5>
                        <p>Stay on top of your health with regular checkups from our skilled medical team.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={d5} alt="Monthly Doctor Visits" className="img-fluid rounded" />
                        <h5 className="mt-2">Monthly Doctor Visits</h5>
                        <p>Monthly visits from healthcare professionals to monitor your health and well-being.</p>
                    </div>
                    <div className="col-md-4">
                        <img src={d6} alt="Engaging Entertainment Activities" className="img-fluid rounded" />
                        <h5 className="mt-2">Engaging Entertainment Activities</h5>
                        <p>Participate in fun activities that promote mental stimulation and social interaction.</p>
                    </div>
                </div>

                <div className="text-center">
                    <h4>Social Engagement Opportunities</h4>
                    <p>We believe in fostering connections and friendships among our residents through group activities and outings.</p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default AssistedLiving;
