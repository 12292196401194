import React, { useState } from 'react';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';
import bg from '../Images/bg1.png';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import {  useNavigate } from 'react-router-dom';

const Signup = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
    });

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const Navigate = useNavigate()

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccessMessage('');

        // Basic validation
        if (!formData.username || !formData.email || !formData.mobile || !formData.password || !formData.confirmPassword) {
            setError('Please fill in all fields.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post('https://ssapi.emedha.in/auth/api/signup', {
                username: formData.username,
                email: formData.email,
                mobile: formData.mobile,
                password: formData.password,
            });

            setSuccessMessage(response.data.message);
            Navigate('/otp-verification')

        } catch (error) {
            if (error.response && error.response.data) {
                setError(error.response.data.error);
            } else {
                setError('An error occurred. Please try again.');
            }
        }
    };

    return (
        <>
            <Landingheader />
            <div style={{marginTop:'80px'}}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', position: 'relative', margin: 0, padding: 0, fontFamily: "'Poppins', sans-serif", }}>
                    <img src={bg} alt="" style={{ position: 'absolute', width: '100%', height: '100%', top: 0, left: 0, objectFit: 'cover', zIndex: -1, }} />
                    <div style={{ width: '400px', color: '#fff', padding: '30px 40px', borderRadius: '15px', background: 'rgba(5, 5, 5, 0.7)', position: 'relative', zIndex: 1, }}>
                        <form onSubmit={handleSubmit}>
                            <h3>Customer Registration</h3>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input type="text" name="username" placeholder="Username" value={formData.username} onChange={handleChange} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input type="tel" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} />
                            </div>

                            <div style={{ position: 'relative', width: '100%', margin: '15px 0' }}>
                                <input type="password" name="confirmPassword" placeholder="Confirm Password" value={formData.confirmPassword} onChange={handleChange} style={{ width: '100%', height: '100%', background: 'transparent', border: 'none', outline: 'none', borderRadius: '5px', color: '#fff', border: '2px solid #fff', fontSize: '16px', padding: '10px', }} />
                            </div>

                            <ReCAPTCHA className='mb-2' sitekey="6LcL4CIqAAAAAHgyMy5VaZzK-RT5gj13587tI1ZU" />

                            <button type="submit" style={{ width: '100%', height: '40px', background: '#07526f', color: '#000', border: 'none', outline: 'none', borderRadius: '20px', boxShadow: '0 0 20px #04fff0', fontSize: '15px', fontWeight: 600, transition: '1s', marginTop: '20px', }}>
                                Sign Up
                            </button>

                            <div style={{ fontSize: '14px', marginTop: '20px', textAlign: 'center' }}>
                                <p>Already have an account? <br /> <a href="/login" style={{ color: 'white' }}>Login</a></p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Signup;
