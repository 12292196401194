import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './sidebar';


const Notifications = () => {

    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);


    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };


    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

                <div className='main-container'>
                    <h1 className='text-center' style={{ color: '#08b4f3' }}>
                        Notifications
                    </h1>
                </div>
            </div>
        </>
    );
}

export default Notifications;
