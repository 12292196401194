import React from 'react';
import Landingheader from '../Header/Landingheader';
import { Carousel } from 'react-bootstrap';
import Image1 from '../Images/s1.jpg';
import Image2 from '../Images/s2.png';
import Image3 from '../Images/s3.png';
import scs from '../Images/scs.png';
import ssc from '../Images/ssc.png';
import dc from '../Images/dc.png';
import Footer from '../Footer/footer';


const Home = () => {



    return (
        <>
            <Landingheader />

            <div style={{marginTop:'80px'}}>
                <Carousel className='mt-2'>
                    <Carousel.Item><img className="d-block w-100 " src={Image1} alt="First slide" /></Carousel.Item>
                    <Carousel.Item><img className="d-block w-100 " src={Image2} alt="Second slide" /></Carousel.Item>
                    <Carousel.Item><img className="d-block w-100 " src={Image3} alt="Third slide" /></Carousel.Item>
                </Carousel>

                <div>
                    <h6 className='text-center mt-3'>Our Services</h6>
                </div>
                <div className="mt-3">
                    <h1 className='text-center'><strong>Welcome to Life Care Services</strong></h1>
                    <p className='text-center mt-3'>For things like customer service, medical care, billing, we have made commitments to providing an exceptionally comprehensive experience.</p>
                </div>



                <div className="container">
                    <div className="row g-4">

                        <div className="col-md-4">
                            <div className="d-flex flex-column justify-content-between p-3 border rounded shadow-sm bg-white text-center" style={{ height: '100%' }}>
                                <img src={scs} style={{ borderRadius: '10px' }} />
                                <h2 className="h5 my-3">Senior Care Services</h2>
                                <p className="text-muted mb-0">
                                    At <strong>Life Care Services</strong>, we prioritize the well-being and comfort of our senior clients. Our dedicated team provides personalized care plans tailored to each individual's needs.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-flex flex-column justify-content-between p-3 border rounded shadow-sm bg-white text-center" style={{ height: '100%' }}>
                                <img src={ssc} style={{ borderRadius: '10px' }} />
                                <h2 className="h5 my-3">Senior Social Centre</h2>
                                <p className="text-muted mb-0">
                                    Our <strong>Senior Social Centre</strong> is a vibrant community hub designed to foster connections and friendships among seniors.
                                </p>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="d-flex flex-column justify-content-between p-3 border rounded shadow-sm bg-white text-center" style={{ height: '100%' }}>
                                <img src={dc} style={{ borderRadius: '10px' }} />
                                <h2 className="h5 my-3">Day Care</h2>
                                <p className="text-muted mb-0">
                                    Our <strong>Day Care</strong> program is perfect for seniors who need supervision and socialization during the day.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>


                <div className="mt-5">
                    <p className="text-center" style={{ fontSize: '20px' }}>
                        <strong>How It Works</strong>
                    </p>
                    <h1 className="text-center mb-4">Supporting Seniors with Heart and Skill</h1>

                    <div className="container">
                        <div className="row align-items-stretch">
                            <div className="col-md-6 d-flex flex-column">
                                <div className="p-4 border rounded shadow-sm flex-fill">
                                    <h5>Personalized Care Plans</h5>
                                    <p>
                                        We create customized care plans tailored to the individual needs of each senior, ensuring their unique preferences and health requirements are met.
                                    </p>
                                </div>
                                <div className="p-4 border rounded shadow-sm flex-fill mt-3">
                                    <h5>Compassionate Caregivers</h5>
                                    <p>
                                        Our team of trained and compassionate caregivers provides emotional support and companionship, making every senior feel valued and cared for.
                                    </p>
                                </div>
                                <div className="p-4 border rounded shadow-sm flex-fill mt-3">
                                    <h5>Engaging Activities</h5>
                                    <p>
                                        We offer a variety of activities designed to stimulate both the mind and body, promoting overall wellness and a sense of community among seniors.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <img src={scs} alt="Senior Care Services" className="img-fluid rounded h-100 w-100" style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>



            </div>

            <Footer />
        </>
    );
};

export default Home;
