
import React from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import A1 from '../Images/logo.png';

const Landingheader = () => {
    return (
        <Navbar expand="lg" fixed="top" style={{ backgroundColor: '#b7c7d2' }}>
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={A1} className='me-auto' style={{ height: '50px' }} alt="Logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto d-lg-flex">
                        <Nav.Link as={Link} to="/"><strong style={{ color: '#07526f' }}>Home</strong></Nav.Link>
                        <Nav.Link as={Link} to="#"><strong className='text-black'>About</strong></Nav.Link>

                        

                        <NavDropdown title={<strong className='text-black'>Services</strong>} id="services-dropdown">
                            <NavDropdown.Item as={Link} to="/services/senior-social-center">Senior Social Center</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/services/day-care">Day Care</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/services/assisted-living">Assisted Living</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/services/transition-care">Transition Care</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/services/interim-care">Interim Care</NavDropdown.Item>
                        </NavDropdown>

                        <Nav.Link as={Link} to="/packages/packages"><strong className='text-black'>Packages</strong></Nav.Link>
                        <Nav.Link as={Link} to="/gallery/gallery"><strong className='text-black'>Gallery</strong></Nav.Link>
                        <Nav.Link as={Link} to="#"><strong className='text-black'>Contact us</strong></Nav.Link>
                    </Nav>
                    <Nav>
                        <NavDropdown title={<strong className='text-black'>Registration</strong>} id="registration-dropdown">
                            <NavDropdown.Item as={Link} to="/signup">Customer Registration</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/vendor">Vendor Registration</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link as={Link} to="/login"><strong className='text-black'>Login</strong></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Landingheader;
