import React from 'react';
import a1 from '../Images/a1.png';
import a2 from '../Images/a2.png';
import a3 from '../Images/a3.png';
import a4 from '../Images/a4.png';
import d1 from '../Images/d1.png';
import d2 from '../Images/d2.png';
import d3 from '../Images/d3.png';
import d4 from '../Images/d4.png';
import d5 from '../Images/d5.png';
import d6 from '../Images/d6.png';
import Landingheader from '../Header/Landingheader';
import Footer from '../Footer/footer';

const Daycare = () => {
    const images = [d1, d2, d3, d4, d5, d6];

    const keyframes = `
        @keyframes scroll {
            from {
                transform: translateX(0);
            }
            to {
                transform: translateX(-${images.length * 240}px); // Adjust this based on image width
            }
        }
    `;

    const styleElement = document.createElement('style');
    styleElement.innerHTML = keyframes;
    document.head.appendChild(styleElement);

    return (
        <>
            <Landingheader />
            <div className="container" style={{marginTop:'80px'}}>
                <h1 className='text-center mt-5' >Welcome to Our Daycare!</h1>

                <div className="mt-4 overflow-hidden">
                    <div style={{ display: 'flex', animation: 'scroll 10s linear infinite', width: `${images.length * 540}px` }}>
                        {images.map((src, index) => (
                            <img
                                key={index}
                                src={src}
                                alt={`Proyecto ${index + 1}`}
                                style={{ height: '300px', width: '540px', borderRadius: '20px', objectFit: 'cover' }}
                                className="img-fluid"
                            />
                        ))}
                    </div>
                </div>

                <p className="text-center mt-5">
                    At our daycare, we provide a safe and nurturing environment for your children. Our dedicated staff ensures that every child receives personalized attention and care. We believe in fostering creativity and learning through play.
                </p>

                <div className="row">
                    {[{ src: a1, title: "Playtime Activities", text: "Engaging activities that promote creativity and social skills." },
                    { src: a2, title: "Learning through Play", text: "Fun learning experiences that make education exciting." },
                    { src: a3, title: "Outdoor Adventures", text: "Exploring nature and outdoor play for healthy development." },
                    { src: a4, title: "Arts and Crafts", text: "Creative projects that enhance fine motor skills." }].map((item, index) => (
                        <div className="col-md-6 col-lg-3 mb-4" key={index}>
                            <div className="card">
                                <img src={item.src} className="card-img-top" alt={item.title} />
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <p className="card-text">{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Daycare;
