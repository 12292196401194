import React, { useState } from 'react';
import './sidebar.css';
import { BsPersonCircle, BsJustify, BsSearch, BsFillEnvelopeFill, BsBoxArrowRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

function Header({ OpenSidebar }) {

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const handleLogout = () => {
        localStorage.removeItem('userData');
        sessionStorage.removeItem('userType');
        navigate('/');
        console.log('Logging out...');
        setDropdownVisible(false);
    };

    return (
        <div className='header'>
            <div className='menu-icon'>
                <BsJustify className='icon' onClick={OpenSidebar} />
            </div>
            <div className='header-left'>
                <BsSearch className='icon' style={{ color: '#17376e' }} />
            </div>
            <div>
                <a href="/notifications/notifications">
                    <BsFillEnvelopeFill size={20} className='me-5' style={{ color: 'white', cursor: 'pointer' }} />
                </a>

                <BsPersonCircle size={20}
                    style={{ color: 'white', cursor: 'pointer' }}
                    onClick={toggleDropdown}
                />
                {dropdownVisible && (
                    <div style={{ position: 'absolute', right: '10px', bottom: '85%', backgroundColor: '#fff', zIndex: 1000, borderRadius: '20px' }}>
                        <button onClick={handleLogout} className='dropdown-items'>
                            <BsBoxArrowRight style={{ marginRight: '5px' }} />
                            Logout
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Header;
