import React from 'react'
import Landingheader from '../Header/Landingheader'
import Footer from '../Footer/footer'

const about = () => {
    return (
        <>
            <Landingheader />
            <div className='mt-5'>about</div>
            <Footer />
        </>
    )
}

export default about