import React, { useState, useEffect } from 'react';
import Header from './Header';
import Sidebar from './sidebar';
import { FaArrowDown } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { MdDelete } from "react-icons/md";
import axios from 'axios';
import { BsSearch } from 'react-icons/bs';

const Adpackages = () => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        image: null,
        price: '',
        description: '',
        duration: '',
    });
    const [packages, setPackages] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [editingIndex, setEditingIndex] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const packagesPerPage = 10;

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle);
    };

    useEffect(() => {
        const fetchPackages = async () => {
            try {
                const response = await axios.get('https://ssapi.emedha.in/packages/api/packages');
                setPackages(response.data);
            } catch (error) {
                console.error('Error fetching packages:', error);
            }
        };
        fetchPackages();
    }, []);

    const handleAddOrUpdatePackage = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        formDataToSend.append('name', formData.name);
        if (formData.image) {
            formDataToSend.append('image', formData.image);
        }
        formDataToSend.append('price', formData.price);
        formDataToSend.append('description', formData.description);
        formDataToSend.append('duration', formData.duration);

        try {
            if (editingIndex !== null) {
                await axios.put(`https://ssapi.emedha.in/packages/api/packages/${packages[editingIndex].id}`, formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                const updatedPackages = packages.map((pkg, index) =>
                    index === editingIndex ? { ...pkg, ...formData } : pkg
                );
                setPackages(updatedPackages);
                setEditingIndex(null); // Reset editing index
            } else {
                const response = await axios.post('https://ssapi.emedha.in/packages/api/packages', formDataToSend, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
                setPackages([...packages, response.data]);
            }

            setFormData({
                name: '',
                image: null,
                price: '',
                description: '',
                duration: '',
            });
            setIsModalOpen(false);
        } catch (error) {
            console.error('Error saving package:', error.response ? error.response.data : error.message);
        }
    };

    const handleEditPackage = (index) => {
        setEditingIndex(index);
        setFormData(packages[index]);
        setIsModalOpen(true);
    };

    const handleDeletePackage = async (index) => {
        const packageToDelete = packages[index];
        try {
            await axios.delete(`https://ssapi.emedha.in/packages/api/packages/${packageToDelete.id}`);
            const updatedPackages = packages.filter((_, i) => i !== index);
            setPackages(updatedPackages);
        } catch (error) {
            console.error('Error deleting package:', error);
        }
    };

    const filteredPackages = packages.filter(pkg =>
        pkg.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.price.toString().includes(searchQuery) ||
        pkg.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
        pkg.duration.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination logic
    const indexOfLastPackage = currentPage * packagesPerPage;
    const indexOfFirstPackage = indexOfLastPackage - packagesPerPage;
    const currentPackages = filteredPackages.slice(indexOfFirstPackage, indexOfLastPackage);

    const totalPages = Math.ceil(filteredPackages.length / packagesPerPage);

    return (
        <>
            <div className='grid-container'>
                <Header OpenSidebar={OpenSidebar} />
                <Sidebar openSidebarToggle={openSidebarToggle} OpenSidebar={OpenSidebar} />

                <div className='main-container'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <h4 style={{ color: '#08b4f3' }}><strong>PACKAGES</strong></h4>
                        <div>
                            <button style={{ backgroundColor: '#1d8dbb', color: 'white', border: 'none', borderRadius: '8px', padding: '5px 10px', cursor: 'pointer', marginRight: '10px' }} onClick={() => { console.log('Export clicked'); }}>
                                <FaArrowDown />Export
                            </button>
                            <button style={{ backgroundColor: '#07526f', color: 'white', border: 'none', borderRadius: '8px', padding: '5px 10px', cursor: 'pointer' }} onClick={() => {
                                setFormData({ name: '', image: null, price: '', description: '', duration: '', }); setEditingIndex(null); setIsModalOpen(true);
                            }}>
                                + Add Package
                            </button>
                        </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px', backgroundColor: '#789ca4', padding: '10px 20px', borderRadius: '8px' }}>
                        <span style={{ fontSize: '18px', color: 'white', fontWeight: 'bold' }}>
                            {filteredPackages.length} Packages
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="text"
                                placeholder="Search..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                style={{ border: '1px solid #ccc', padding: '8px', marginRight: '10px', borderRadius: '20px', outline: 'none', transition: 'border-color 0.3s', width: '200px' }} onFocus={(e) => e.target.style.borderColor = '#08b4f3'} onBlur={(e) => e.target.style.borderColor = '#ccc'} />
                            <BsSearch title="Search" style={{ fontSize: '20px', color: 'white', cursor: 'pointer' }} />
                        </div>
                    </div>

                    {/* Table for displaying packages */}
                    <table style={{ marginTop: '20px', width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                            <tr style={{ backgroundColor: '#b7c7d2' }}>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Package Name</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Image</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Price</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Description</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Duration</th>
                                <th style={{ border: '1px solid black', padding: '8px' }}>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPackages.map((pkg, index) => (
                                <tr key={pkg.id}>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{pkg.name}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        {pkg.image ? <img src={pkg.image} alt={pkg.name} style={{ width: '50px', height: '50px' }} /> : 'No image'}
                                    </td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{pkg.price}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{pkg.description}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>{pkg.duration}</td>
                                    <td style={{ border: '1px solid black', padding: '8px' }}>
                                        <button className="btn btn-primary btn-sm" onClick={() => handleEditPackage(index)}>Edit</button>
                                        <button className="btn btn-sm" onClick={() => handleDeletePackage(index)} style={{ marginLeft: '5px' }}><MdDelete /></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    <div style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                        <button
                            onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                            disabled={currentPage === 1}
                            style={{ marginRight: '10px', borderRadius: '10px' }}
                        >
                            Previous
                        </button>
                        <span>{currentPage} / {totalPages}</span>
                        <button
                            onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                            disabled={currentPage === totalPages}
                            style={{ marginLeft: '10px', borderRadius: '10px' }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>

            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} size="lg">
                <Modal.Header closeButton>
                    <h4 style={{ color: '#08b4f3' }}><strong>{editingIndex !== null ? 'EDIT PACKAGE' : 'ADD PACKAGE'}</strong></h4>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddOrUpdatePackage}>
                        <div className="mb-3">
                            <label className="form-label">Package Name:</label>
                            <input type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={formData.name} onChange={(e) => setFormData({ ...formData, name: e.target.value })} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Package Image:</label>
                            <input type="file" style={{ border: '1px solid black', padding: '10px', width: '100%' }} accept="image/*" onChange={(e) => setFormData({ ...formData, image: e.target.files[0] })} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Package Price:</label>
                            <input type="number" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Package Description:</label>
                            <textarea style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={formData.description} onChange={(e) => setFormData({ ...formData, description: e.target.value })} required />
                        </div>
                        <div className="mb-3">
                            <label className="form-label">Package Duration:</label>
                            <input type="text" style={{ border: '1px solid black', padding: '10px', width: '100%' }} value={formData.duration} onChange={(e) => setFormData({ ...formData, duration: e.target.value })} required />
                        </div>
                        <Modal.Footer>
                            <Button variant="secondary btn-sm" onClick={() => setIsModalOpen(false)}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary btn-sm">
                                {editingIndex !== null ? 'Update' : 'Submit'}
                            </Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Adpackages;
